import { type UseQueryResult, useQuery } from '@tanstack/react-query';

import { featureControl } from '@/feature/toggle';
import { useNavbarQuery } from '@/hooks/useNavbarQuery';
import { TeamKind } from '@/services/common/types';
import { type QueryMap, type TeamKindResult, infoQueryService } from '@/services/users';

type SosCustomizationAccess = {
  teamType: TeamKind | '';
  teamId: number;
  /**
   * Can view package data
   */
  canView: boolean;
  /**
   * Can create/delete package
   */
  canCreateDelete: boolean;
};

const query = {
  team_member_permissions: ['technician_manager'],
  team_info: ['is_expired'],
} satisfies QueryMap;

type TeamResult = TeamKindResult<typeof query>;

export const useSosCustomizationAccessQuery: () => UseQueryResult<SosCustomizationAccess> = featureControl.getToggle(
  'PCP_3282__SOS_Customization__fix_permission_check',
)
  ? useSosCustomizationAccessQuery_new
  : useSosCustomizationAccessQuery_deprecated;

export function useSosCustomizationAccessQuery_new(): UseQueryResult<SosCustomizationAccess> {
  const navbarQuery = useNavbarQuery({ select: (data) => Boolean(data.main_menus.management?.sos_package) });

  return useQuery({
    queryKey: ['info-query', 'sos-customization-access'],
    queryFn: () => infoQueryService.execute(query),
    retry: false,
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 5 * 60 * 1000, // 5 minutes
    enabled: navbarQuery.isFetched,
    select: (data): SosCustomizationAccess => {
      const availableTeam = getAvailableTeam(data);

      if (!availableTeam || !navbarQuery.data) return { teamType: '', teamId: NaN, canView: false, canCreateDelete: false };

      const { teamKind, teamResult } = availableTeam;

      return {
        teamType: teamKind,
        teamId: teamResult.team_id ?? NaN,
        canView: true,
        canCreateDelete: isTeamNotExpired(teamResult),
      };
    },
  });
}

export function useSosCustomizationAccessQuery_deprecated(): UseQueryResult<SosCustomizationAccess> {
  return useQuery({
    queryKey: ['info-query', 'sos-customization-access'],
    queryFn: () => infoQueryService.execute(query),
    retry: false,
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 5 * 60 * 1000, // 5 minutes
    select: (data): SosCustomizationAccess => {
      const availableTeam = getAvailableTeam(data);

      if (!availableTeam) return { teamType: '', teamId: NaN, canView: false, canCreateDelete: false };

      const { teamKind, teamResult } = availableTeam;

      return {
        teamType: teamKind,
        teamId: teamResult.team_id ?? NaN,
        canView: true,
        canCreateDelete: isTeamNotExpired(teamResult),
      };
    },
  });
}

/**
 * The user should have `technician_manager` permission to access the SOS customization.
 * If the team is expired, the user can only view the package data.
 *
 * Priority:
 * 1. SOS (active)
 * 2. STE / Splashtop (active)
 * 3. SOS (expired)
 * 4. STE / Splashtop (expired)
 */
function getAvailableTeam(data: Partial<Record<TeamKind, TeamResult>>): { teamKind: TeamKind; teamResult: TeamResult } | null {
  const availableTeamResults = Object.entries(data)
    .filter(([_teamKind, teamResult]) => isTechnicianManager(teamResult))
    .map(([teamKind, teamResult]) => ({
      teamKind: teamKind as TeamKind,
      teamResult,
    }));

  if (availableTeamResults.length === 0) return null;

  const activeTeam = availableTeamResults.find(({ teamResult }) => isTeamNotExpired(teamResult));
  if (activeTeam) return activeTeam;

  const sosTeam = availableTeamResults.find(({ teamKind }) => teamKind === 'sos');
  if (sosTeam) return sosTeam;

  return availableTeamResults[0];
}

function isTechnicianManager(teamResult: TeamResult): boolean {
  return teamResult.team_member_permissions.technician_manager;
}

function isTeamNotExpired(teamResult: TeamResult): boolean {
  return !teamResult.team_info.is_expired;
}
