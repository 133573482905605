import axios from 'axios';
import { array, z } from 'zod';

import { filterSuccessButOffline } from '@/modules/EndpointSecurity/ActionBar/filterSuccessButOffline';
import { BITDEFENDER_1, BITDEFENDER_2 } from '@/modules/EndpointSecurity/constants';
import { checkResponse } from '@/services/utils';

const teamAntivirusInfoSchema = z.object({
  antivirus_licenses: z.number(),
  used_licenses: z.number(),
  installed_antivirus: z.number(),
  is_migrating: z.boolean().optional(),
  has_expired_bitdefender: z.boolean(),
  has_expired_bitdefender2: z.boolean(),
  bitdefender1_endtime: z.union([z.string(), z.null()]),
  bitdefender2_endtime: z.union([z.string(), z.null()]),
  bitdefender2_tiers: array(
    z.object({
      level: z.number(),
      min: z.number(),
      max: z.string().nullable(),
      price: z.string().nullable(),
    }),
  ).optional(),
});

export type TeamAntivirusInfo = z.infer<typeof teamAntivirusInfoSchema>;

export function getTeamAntivirusInfo(teamId: number) {
  return checkResponse<TeamAntivirusInfo>(axios.get(`/api/web/v1/teams/${teamId}/antivirus/info`), teamAntivirusInfoSchema);
}

const antivirusInstallSchema = z.object({
  success: z.array(z.number()),
  fail: z.array(z.number()),
  offline: z.array(z.number()),
  not_support: z.array(z.number()),
  reach_limit: z.array(z.number()),
  unable_to_do: z.array(z.number()),
});

type AntivirusInstall = z.infer<typeof antivirusInstallSchema>;

export function antivirusInstall(
  teamId: number,
  computerIds: Array<number>,
  software: string,
  reboot_before_install: boolean,
  selectedId: number,
) {
  return checkResponse<AntivirusInstall>(
    axios.post(`/api/web/v1/teams/${teamId}/antivirus/computers/install`, {
      ids: computerIds,
      software,
      reboot_before_install,
      ...(software === BITDEFENDER_1 && { package_id: selectedId }),
      ...(software === BITDEFENDER_2 && { policy_id: selectedId }),
    }),
    antivirusInstallSchema,
  ).then((res) => {
    return {
      ...res,
      success: filterSuccessButOffline(res.success, res.offline),
    };
  });
}

export const antivirusInstallErrorSchema = z.union([
  z.object({
    result: z.literal(40403),
    data: z.object({
      error: z.string(), // TODO: Could check actual error message later
    }),
  }),
  z.object({
    result: z.literal(40404),
    data: z.object({
      error: z.string(), // TODO: Could check actual error message later
    }),
  }),
]);

const antivirusUninstallSchema = z.object({
  success: z.array(z.number()),
  fail: z.array(z.number()),
});

type AntivirusUninstall = z.infer<typeof antivirusUninstallSchema>;

export function antivirusUninstall(teamId: number, computerIds: Array<number>, software: string) {
  return checkResponse<AntivirusUninstall>(
    axios.post(`/api/web/v1/teams/${teamId}/antivirus/computers/uninstall`, { ids: computerIds, software }),
    antivirusUninstallSchema,
  );
}

const antivirusStopInstallSchema = z.object({
  success: z.array(z.number()),
  fail: z.array(z.number()),
});

type AntivirusStopInstall = z.infer<typeof antivirusStopInstallSchema>;

export function antivirusStopInstall(teamId: number, computerIds: Array<number>, software: string) {
  return checkResponse<AntivirusStopInstall>(
    axios.post(`/api/web/v1/teams/${teamId}/antivirus/computers/stop_installation`, { ids: computerIds, software }),
    antivirusStopInstallSchema,
  );
}

const antivirusResetInstallSchema = z.object({
  success: z.array(z.number()),
  fail: z.array(z.number()),
});

type AntivirusResetInstall = z.infer<typeof antivirusResetInstallSchema>;

export function antivirusResetInstallation(teamId: number, computerIds: Array<number>, software: string) {
  return checkResponse<AntivirusResetInstall>(
    axios.post(`/api/web/v1/teams/${teamId}/antivirus/computers/reset_installation`, { ids: computerIds, software }),
    antivirusResetInstallSchema,
  );
}

const refreshStatusSchema = z.object({
  success: z.array(z.number()),
  fail: z.array(z.number()),
  offline: z.array(z.number()),
});

type RefreshStatus = z.infer<typeof refreshStatusSchema>;

export function updateEndpointStatus(teamId: number, computerIds: Array<number>) {
  return checkResponse<RefreshStatus>(
    axios.post(`/api/web/v1/teams/${teamId}/antivirus/computers/refresh_status`, { ids: computerIds }),
    refreshStatusSchema,
  ).then((res) => {
    return {
      ...res,
      success: filterSuccessButOffline(res.success, res.offline),
    };
  });
}

const antivirusScanSchema = z.object({
  success: z.array(z.number()),
  fail: z.array(z.number()),
});

type AntivirusScan = z.infer<typeof antivirusScanSchema>;

export function antivirusScan(teamId: number, computerIds: Array<number>, software: string, scan_type: string) {
  return checkResponse<AntivirusScan>(
    axios.post(`/api/web/v1/teams/${teamId}/antivirus/computers/scan`, { ids: computerIds, software, scan_type }),
    antivirusScanSchema,
  );
}

const antivirusTempDisableSchema = z.object({
  success: z.array(z.number()),
  fail: z.array(z.number()),
});

type AntivirusTempDisable = z.infer<typeof antivirusTempDisableSchema>;

export function antivirusTempDisable(teamId: number, computerIds: Array<number>, software: string, minutes: string) {
  // TODO: Check the condition later
  const minutesParam = {
    '15': 900,
    '30': 1800,
    '-1': -1,
  }[minutes];
  return checkResponse<AntivirusTempDisable>(
    axios.post(`/api/web/v1/teams/${teamId}/antivirus/computers/temp_disable`, {
      ids: computerIds,
      software,
      disable_time: minutesParam,
    }),
    antivirusTempDisableSchema,
  );
}

const antivirusEnableSchema = z.object({
  success: z.array(z.number()),
  fail: z.array(z.number()),
});

type AntivirusEnable = z.infer<typeof antivirusEnableSchema>;

export function antivirusEnable(teamId: number, computerIds: Array<number>, software: string) {
  return checkResponse<AntivirusEnable>(
    axios.post(`/api/web/v1/teams/${teamId}/antivirus/computers/enable`, { ids: computerIds, software }),
    antivirusEnableSchema,
  );
}

const antivirusMigrationSchema = z.object({});

type AntivirusMigration = z.infer<typeof antivirusMigrationSchema>;

export function antivirusMigration(teamId: number, software: string, ignore_unsupport_servers = false) {
  return checkResponse<AntivirusMigration>(
    axios.post(`/api/web/v1/teams/${teamId}/antivirus/computers/migration`, { software, ignore_unsupport_servers }),
    antivirusMigrationSchema,
  );
}

export const antivirusMigrationErrorSchema = z.union([
  z.object({
    result: z.literal(40416),
    data: z.object({
      servers: z.array(z.number()),
    }),
  }),
  z.object({
    result: z.literal(41403),
    commands: z.object({}),
    messages: z.array(z.any()),
    data: z.object({
      error: z.literal('team_member_permissions'),
    }),
  }),
]);

const antivirusPolicyAssignSchema = z.object({
  success: z.array(z.number()),
  fail: z.array(z.number()),
  miss_endpoint_ids: z.array(z.number()), // The value miss_endpoint_id is not used in this module.
});

type AntivirusPolicyAssign = z.infer<typeof antivirusPolicyAssignSchema>;

export function antivirusPolicyAssign(teamId: number, computerIds: Array<number>, policyId: number) {
  return checkResponse<AntivirusPolicyAssign>(
    axios.patch(`/api/web/v1/teams/${teamId}/antivirus/policies/${policyId}/computers/assign`, { add: computerIds }),
    antivirusPolicyAssignSchema,
  );
}

// bitdefender 2 Policy in bitdefender 1 named package
const antivirusPackageListSchema = z.array(
  z.object({
    id: z.number(),
    name: z.string(),
    default: z.boolean(),
  }),
);

type AntivirusPackageList = z.infer<typeof antivirusPackageListSchema>;

export function antivirusPackageList(teamId: number) {
  return checkResponse<AntivirusPackageList>(axios.get(`/api/web/v1/teams/${teamId}/antivirus/packages`), antivirusPackageListSchema);
}

const updateEndpointVirusDefinitionSchema = z.object({
  success: z.array(z.number()),
  fail: z.array(z.number()),
  offline: z.array(z.number()),
});

type UpdateEndpointVirusDefinition = z.infer<typeof updateEndpointVirusDefinitionSchema>;

const method = 'POST';

const getUrl = (teamId: number) => `/api/web/v1/teams/${teamId}/antivirus/computers/update_virus_definition`;

/**
 * @param teamId
 * @param computerIds selected computer ids
 * @doc https://splashtop.atlassian.net/wiki/x/QgGiSw
 */
function updateEndpointVirusDefinition(teamId: number, computerIds: Array<number>, software: string) {
  return checkResponse<UpdateEndpointVirusDefinition>(
    axios.request({
      method,
      url: getUrl(teamId),
      data: { ids: computerIds, software },
    }),
    updateEndpointVirusDefinitionSchema,
  ).then((res) => {
    return {
      ...res,
      success: filterSuccessButOffline(res.success, res.offline),
    };
  });
}

export const updateEndpointVirusDefinitionService = {
  method,
  getUrl,
  execute: updateEndpointVirusDefinition,
} as const;
