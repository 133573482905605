import { useQuery } from '@tanstack/react-query';

import { useUserProfile } from '@/models/UserProfile';
import { isGroupAdmin, shouldShowServiceDeskSupportSession } from '@/modules/ServiceDesk';
import { infoQueryService } from '@/services/users';

import type { TeamInfo } from './types';

export function useTeamInfoForSupportSessionQuery() {
  const profile = useUserProfile();
  return useQuery({
    queryKey: ['info-query', 'support-session'],
    queryFn: async () =>
      infoQueryService.execute({
        team_info: ['is_expired'],
        team_permissions: ['sos_service_desk'],
        team_member_info: ['role', 'member_id', 'group_scope', 'member_status'],
        team_member_permissions: ['attended_access', 'technician_manager'],
      }),
    retry: false,
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 5 * 60 * 1000, // 5 minutes
    select: (data): TeamInfo => {
      // Handle the abnormal case that if a user has duplicated service desk permission from both sos and srs, we should use sos first
      if (
        data.sos &&
        shouldShowServiceDeskSupportSession({
          memberStatus: data.sos.team_member_info.member_status,
          sosServiceDesk: data.sos.team_permissions.sos_service_desk,
          attendedAccess: data.sos.team_member_permissions.attended_access,
        })
      ) {
        return {
          teamType: 'sos',
          teamId: data.sos.team_id ?? NaN,
          memberId: data.sos.team_member_info.member_id,
          timeZoneMinutes: profile.time_zone_minutes,
          technicianManager: data.sos.team_member_permissions.technician_manager,
          isExpired: data.sos.team_info.is_expired,
          isGroupAdmin: isGroupAdmin({ role: data.sos.team_member_info.role, groupScope: data.sos.team_member_info.group_scope }),
          email: profile.email,
        };
      }
      // Handle the rest case
      return Object.entries(data).reduce(
        (result, [teamType, { team_id, team_info, team_member_permissions, team_member_info, team_permissions }]) => {
          if (
            shouldShowServiceDeskSupportSession({
              memberStatus: team_member_info.member_status,
              sosServiceDesk: team_permissions.sos_service_desk,
              attendedAccess: team_member_permissions.attended_access,
            })
          ) {
            return {
              teamType: teamType,
              teamId: team_id ?? NaN,
              memberId: team_member_info.member_id,
              timeZoneMinutes: profile.time_zone_minutes,
              technicianManager: team_member_permissions.technician_manager,
              isExpired: team_info.is_expired,
              isGroupAdmin: isGroupAdmin({ role: team_member_info.role, groupScope: team_member_info.group_scope }),
              email: profile.email,
            };
          }
          return result;
        },
        {
          teamType: '',
          teamId: -1,
          memberId: -1,
          timeZoneMinutes: 0,
          technicianManager: false,
          isExpired: true,
          isGroupAdmin: false,
          email: '',
        } as TeamInfo,
      );
    },
  });
}
