import axios from 'axios';
import { z } from 'zod';

import { ServiceObject } from '@/services/types';
import { checkResponse } from '@/services/utils';

const METHOD = 'GET';

type Mode = 'simple'; // Currently we only implement the 'simple' mode
const getUrl = ({ mode }: { mode: Mode }) => `/api/web/v1/ui/navbar?${new URLSearchParams({ mode }).toString()}`;

const simpleModeSchema = z.object({
  main_menus: z.object({
    dashboard: z.boolean().optional(),
    computers: z.boolean().optional(),
    logs: z
      .object({
        active_sessions: z.boolean().optional(),
        completed_sessions: z
          .object({
            sessions: z.boolean().optional(),
            file_sessions: z.boolean().optional(),
            file_transfer: z.boolean().optional(),
            chat_sessions: z.boolean().optional(),
            chat_transcript: z.boolean().optional(),
            cmpt_sessions: z.boolean().optional(),
            premium_tool: z.boolean().optional(),
          })
          .optional(),
        log_service_desk_section: z
          .object({
            service_desk_support_sessions: z.boolean().optional(),
            service_desk_setting_changes: z.boolean().optional(),
          })
          .optional(),
        premium_section: z
          .object({
            windows_updates: z.boolean().optional(),
            alert: z.boolean().optional(),
            smart_actions: z.boolean().optional(),
            one_to_many_actions: z.boolean().optional(),
            endpoint_security: z.boolean().optional(),
          })
          .optional(),
        service_desk_reports: z.boolean().optional(),
      })
      .optional(),
    management: z
      .object({
        users: z.boolean().optional(),
        computers: z.boolean().optional(),
        clients: z.boolean().optional(),
        grouping: z.boolean().optional(),
        scheduled_access: z.boolean().optional(),
        deployment_package: z.boolean().optional(),
        sos_package: z.boolean().optional(),
        service_desk: z
          .object({
            service_desk_channels: z.boolean().optional(),
            service_desk_survey: z.boolean().optional(),
          })
          .optional(),
        premium_section: z
          .object({
            endpoint_policies: z.boolean().optional(),
            alert: z.boolean().optional(),
            one_to_many_actions: z.boolean().optional(),
            one_to_many_schedules: z.boolean().optional(),
            windows_updates: z.boolean().optional(),
            endpoint_security: z.boolean().optional(),
            antivirus_threats: z.boolean().optional(),
            inventory: z.boolean().optional(),
          })
          .optional(),
      })
      .optional(),
    sos: z.boolean().optional(),
    service_desk: z.boolean().optional(),
    discover_message: z.boolean().optional(),
    discover: z.boolean().optional(),
    upgrade: z.boolean().optional(),
  }),
});

export type SimpleModeResult = z.infer<typeof simpleModeSchema>;

const schemaMap = {
  simple: simpleModeSchema,
} satisfies Record<Mode, z.ZodType>;

const execute = ({ mode = 'simple' }: { mode?: Mode } = {}) =>
  checkResponse(axios.request({ method: METHOD, url: getUrl({ mode }) }), schemaMap[mode]);

/**
 * Navbar API service
 *
 * @see {@link https://splashtop.atlassian.net/wiki/spaces/CS/pages/1254261170/NavBar+API}
 */
export const navbarService = {
  method: METHOD,
  getUrl,
  execute,
} satisfies ServiceObject;
