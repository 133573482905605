/**
 * GET SRS Software Patch Opportunities for List
 * @NOTICE This service belongs to the EMM API microservice, /emm, not /web.
 * @see https://splashtop.atlassian.net/wiki/spaces/CS/pages/1101791809/4+EMM+API+Software+Patch#4.1.8------GET-SRS-Software-Patch-Opportunities-for-List
 */
import axios from 'axios';
import { formatInTimeZone } from 'date-fns-tz';
import { z } from 'zod';

import { opportunityStatusMap } from '@/modules/SoftwareManagement/constants';

import { checkResponse } from '../utils';
import { EMM_API_DOMAIN } from './constants';

const method = 'GET';

export const searchModeSchema = z.nativeEnum({
  verbose: 'verbose',
  simple: 'simple',
} as const);

export type SearchMode = z.infer<typeof searchModeSchema>;

/**
 * Service provider, 1 = choco, 2 = brew
 */
const apiServiceProviderSchema = z.union([z.literal(1), z.literal(2)]);
export type APIServiceProvider = z.infer<typeof apiServiceProviderSchema>;

const apiOpportunityStatusSchema = z.nativeEnum(opportunityStatusMap);
export type APIOpportunityStatus = z.infer<typeof apiOpportunityStatusSchema>;

const serviceProviderSchema = z.nativeEnum({
  choco: 'choco',
  brew: 'brew',
} as const);

const paramSchema = z.object({
  teamId: z.number(),
  serverId: z.number().optional(),
  serviceProvider: serviceProviderSchema.optional(),
  appId: z.string().optional(),
  deviceUser: z.string().optional(),
  targetVersion: z.string().optional(),
  opportunityStatus: z.nativeEnum(opportunityStatusMap).optional(),
  searchMode: searchModeSchema.default(searchModeSchema.enum.simple).optional(),
  startTime: z.string().optional(),
  endTime: z.string().optional(),
});
export type Param = z.infer<typeof paramSchema>;

const opportunityBaseSchema = z.object({
  id: z.string(),
  ver: z.number(),
  team_id: z.number(),
  server_id: z.number(),
  dev_user: z.string(),
  sp: apiServiceProviderSchema,
  app: z.string(),
  target_version: z.string(),
  current_version: z.string(),
  status: apiOpportunityStatusSchema,
  created_at: z.string(),
});
export type OpportunityBase = z.infer<typeof opportunityBaseSchema>;

const opportunityVerboseSchema = opportunityBaseSchema.merge(
  z.object({
    release_date: z.string().nullable(),
    approved: z
      .array(
        z.object({
          at: z.string(),
          by: z.string().nullable(),
        }),
      )
      .nullable(),
    rejected: z
      .array(
        z.object({
          at: z.string(),
          by: z.string().nullable(),
        }),
      )
      .nullable(),
    failed: z
      .array(
        z.object({
          at: z.string(),
          reason: z.number(),
        }),
      )
      .nullable(),
    scheduled: z
      .array(
        z.object({
          scheduled_at: z.string(),
          scheduled_by: z.string(),
          scheduled_update_at: z.string(),
        }),
      )
      .nullable(),
    installed_at: z.string().nullable(),
  }),
);
export type OpportunityVerbose = z.infer<typeof opportunityVerboseSchema>;

const softwareSchema = z.object({
  id: z.string(),
  latest_only: z.boolean(),
  sp: apiServiceProviderSchema,
  app: z.string(),
  icon_url: z.string(),
  name: z.string(),
  description: z.string().nullable(),
  policy_item_code: z.number(),
});
export type Software = z.infer<typeof softwareSchema>;

export type OpportunityResult<P extends Param> = P['searchMode'] extends typeof searchModeSchema.enum.verbose
  ? OpportunityVerbose
  : OpportunityBase;

function getOpportunitySchema<P extends Param>(param: P): z.ZodType<OpportunityResult<P>> {
  const searchMode = param.searchMode ?? 'simple';
  return (searchMode === searchModeSchema.enum.verbose ? opportunityVerboseSchema : opportunityBaseSchema) as unknown as z.ZodType<
    OpportunityResult<P>
  >;
}

type Response<P extends Param> = { ops: Array<OpportunityResult<P>>; softwares: Array<Software> };
function getResponseSchema<P extends Param>(search: P): z.ZodType<Response<P>> {
  const opportunitySchema = getOpportunitySchema<P>(search);
  return z.object({
    ops: z.array(opportunitySchema),
    softwares: z.array(softwareSchema),
  });
}

const getUrl = (queryString: string) => `https://${EMM_API_DOMAIN}/api/emm/v1/pcp_patch_ops?${queryString}`;

/** Service provider, 1 = choco, 2 = brew */
const SERVICE_PROVIDER = {
  [serviceProviderSchema.enum.choco]: '1',
  [serviceProviderSchema.enum.brew]: '2',
} as const;

/** Search mode, verbose = 1, simple = 2 */
export const SEARCH_MODE = {
  [searchModeSchema.enum.verbose]: '1',
  [searchModeSchema.enum.simple]: '2',
} as const;

type Exact<T, U extends T> = T & { [K in keyof U]: K extends keyof T ? U[K] : never };

function execute<P extends Param>(param: Exact<Param, P>, emmAccessToken: string) {
  const parsedParam = paramSchema.parse(param);

  const queryString = new URLSearchParams({
    /** The team ID of the target team */
    team_id: String(parsedParam.teamId),
  });
  /** The server ID of a streamer */
  if (parsedParam.serverId) {
    queryString.append('server_id', String(parsedParam.serverId));
  }
  /** Service provider */
  if (parsedParam.serviceProvider) {
    queryString.append('sp', SERVICE_PROVIDER[parsedParam.serviceProvider]);
  }
  /** App ID */
  if (parsedParam.appId) {
    queryString.append('app', parsedParam.appId);
  }
  /** Device user  */
  if (parsedParam.deviceUser) {
    queryString.append('dev_user', parsedParam.deviceUser);
  }
  /** Target streamer version
   * @example 3.7.4.0
   */
  if (parsedParam.targetVersion) {
    queryString.append('target_version', parsedParam.targetVersion);
  }
  /** Opportunity status */
  if (parsedParam.opportunityStatus || parsedParam.opportunityStatus === 0) {
    queryString.append('op_status', String(parsedParam.opportunityStatus));
  }
  /**
   * Search mode
   * verbose = 1, simple = 2
   * @default 2
   */
  if (parsedParam.searchMode) {
    queryString.append('mode', SEARCH_MODE[parsedParam.searchMode]);
  }
  /** Start time for the time period range */
  if (parsedParam.startTime) {
    const formattedStart = formatInTimeZone(parsedParam.startTime, 'UTC', 'yyyy-MM-dd HH:mm:ss');
    queryString.append('start_time', formattedStart);
  }
  /** End time for the time period range */
  if (parsedParam.endTime) {
    const formattedEnd = formatInTimeZone(parsedParam.endTime, 'UTC', 'yyyy-MM-dd HH:mm:ss');
    queryString.append('end_time', formattedEnd);
  }

  return checkResponse(
    axios({ method, url: getUrl(queryString.toString()), headers: { 'x-emm-Authorization': `Bearer ${emmAccessToken}` } }),
    getResponseSchema<P>(param),
  );
}

export const getPatchOpsService = {
  method,
  getUrl,
  execute,
  getSchema: getResponseSchema,
};
