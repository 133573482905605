import { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';
import { atomWithReset, useResetAtom } from 'jotai/utils';
import { useTranslation } from 'next-i18next';

import Spinner from '@/components/Spinner';
import { useRippleFlashMessage } from '@/design';
import { useRefreshBasicProfile } from '@/models/BasicProfile/hooks';
import { useUserProfile } from '@/models/UserProfile';
import { useEmailVerificationModal } from '@/modules/Computer/ComputerList/components/EmailVerificationModal';
import { ComputerScope } from '@/modules/Computer/ComputerList/types';
import { ErrorResponse } from '@/services/common/types';
import { checkBackgroundActions, getTeamComputerUUID, getUserComputerUUID } from '@/services/computers';
import type { BackgroundActionsKind } from '@/services/computers';
import { sendVerificationEmailService } from '@/services/users/sendVerificationEmail';
import { showErrorFlashMessage } from '@/utils/errorHandling';
import useTeamInformation from '@/utils/useTeamInformation';

import { Require2SVModal, useRequire2SVModal } from './Require2SVModal';

const backgroundActionsAtom = atomWithReset<{
  isTriggered: boolean;
  computerScope: ComputerScope | null;
  computerId: number | null;
  kind: BackgroundActionsKind | null;
}>({
  isTriggered: false,
  computerScope: null,
  computerId: null,
  kind: null,
});

export function useBackgroundActions() {
  const userProfile = useUserProfile();
  const refreshBasicProfile = useRefreshBasicProfile();

  const set = useSetAtom(backgroundActionsAtom);
  const emailVerificationModal = useEmailVerificationModal();

  return {
    execute({ computerScope, computerId, kind }: { computerScope: ComputerScope; computerId: number; kind: BackgroundActionsKind }) {
      const executeBackgroundActions = () => set({ isTriggered: true, computerScope, computerId, kind });

      if (userProfile.email_verified) {
        executeBackgroundActions();
        return;
      }

      // check email verified
      refreshBasicProfile()
        .then((data) => {
          const emailVerified = data.data?.profile.email_verified;

          if (emailVerified) {
            executeBackgroundActions();
          } else {
            // send verification email
            return sendVerificationEmailService
              .execute({ requested_from: 'premium_tool' })
              .then(() => {
                // open email verification modal to notify user
                emailVerificationModal.open({ email: userProfile.email });
              })
              .catch((error) => {
                const isEmailVerificationVerified = sendVerificationEmailService.errorHandling.isEmailVerificationVerified(error);

                if (isEmailVerificationVerified) {
                  // Already verified, can execute
                  executeBackgroundActions();
                } else {
                  showErrorFlashMessage({ error });
                }
              });
          }
        })
        .catch((error) => {
          showErrorFlashMessage({ error });
        });
    },
  };
}

export function BackgroundActions(): React.JSX.Element {
  const { t } = useTranslation();
  const teamInformation = useTeamInformation();
  const teamId = teamInformation?.teamId ?? 0;

  const { isTriggered, computerScope, computerId, kind } = useAtomValue(backgroundActionsAtom);
  const reset = useResetAtom(backgroundActionsAtom);
  const require2SVModal = useRequire2SVModal();
  const { flashMessage } = useRippleFlashMessage();

  const { isFetching, data, error, isSuccess, isError } = useQuery<{ session_url: string } | null, ErrorResponse>({
    queryKey: ['computerList', 'checkBackgroundActions', teamId, computerScope, computerId, kind],
    queryFn: async () => {
      if (computerId && kind && computerScope) {
        let devUUID;
        switch (computerScope) {
          case 'team': {
            devUUID = await getTeamComputerUUID({ teamId, computerId });
            break;
          }
          case 'user': {
            devUUID = await getUserComputerUUID({ computerId });
            break;
          }
        }

        if (devUUID) return checkBackgroundActions(devUUID, kind);
        else {
          throw new Error('Unexpected Error');
        }
      }
      return null as never;
    },
    enabled: isTriggered,
    gcTime: 0,
  });

  useEffect(() => {
    if (isSuccess && data) {
      window.open(data.session_url, '_blank', 'noopener,noreferrer');
      reset();
    }
  }, [isSuccess, data, reset]);

  useEffect(() => {
    if (isError && error) {
      const errorResponse = error;
      if (errorResponse.errorReason.error === 'required_2sv') {
        require2SVModal.open();
      } else {
        flashMessage({ variant: 'error', title: t('common:unexpectedError') });
      }
      reset();
    }
  }, [isError, error, require2SVModal, flashMessage, t, reset]);

  return (
    <>
      {isFetching && <Spinner />}
      <Require2SVModal />
    </>
  );
}
