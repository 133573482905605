import { type UseQueryOptions, useQuery } from '@tanstack/react-query';

import { type SimpleModeResult, navbarService } from '@/services/ui/navbar/navbarService';

export function useNavbarQuery<SelectResult>({ select }: Pick<UseQueryOptions<SimpleModeResult, any, SelectResult>, 'select'> = {}) {
  return useQuery({
    queryKey: ['ui', 'navbar'],
    queryFn: () => navbarService.execute(),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    select,
  });
}
