import { type Draft, produce } from 'immer';
import { atom } from 'jotai';

import { basicProfileAtom } from '@/models/BasicProfile';
import { type Reason, sendVerificationEmailService } from '@/services/users/sendVerificationEmail';

import type { UserProfile } from './types';

type DraftFn = (draft: Draft<UserProfile>) => void | UserProfile;

export const userProfileAtom = atom(
  (get) => get(basicProfileAtom).profile,
  (get, set, draftFn: DraftFn) => {
    const basicProfile = get(basicProfileAtom);

    set(basicProfileAtom, { ...basicProfile, profile: produce(basicProfile.profile, draftFn) });
  },
);

/**
 * Check email verification status.
 *
 * @example
 * ```ts
 * const checkEmailVerificationAction = useSetAtom(checkEmailVerificationAtom);
 *
 * checkEmailVerificationAction({ reason: 'premium_tool' })
 *   .then(({ verified }) => {
 *     if (verified) {
 *       // Email is already verified
 *     } else {
 *       // Email is not verified
 *     }
 *   })
 *   .catch((error) => {
 *     // Unexpected error
 *   });
 */
export const checkEmailVerificationAtom = atom(null, async (get, set, { reason }: { reason: Reason }): Promise<{ verified: boolean }> => {
  const { email_verified } = get(userProfileAtom);

  if (email_verified) {
    return Promise.resolve({ verified: true });
  }

  return sendVerificationEmailService
    .execute({ requested_from: reason })
    .then(() => {
      return Promise.resolve({ verified: false });
    })
    .catch((error) => {
      if (sendVerificationEmailService.errorHandling.isEmailVerificationVerified(error)) {
        set(userProfileAtom, (draft) => {
          draft.email_verified = true;
        });

        return Promise.resolve({ verified: true });
      }

      return Promise.reject(error);
    });
});
