import * as Sentry from '@sentry/nextjs';
import { compare } from 'compare-versions';
import UAParser from 'ua-parser-js';

import { DEVELOPMENT_ONLY, LOCAL_ONLY } from '@/feature/toggle';
import { getOSType } from '@/utils/computers';

import type { StreamerType } from './types';

export function checkIsPassiveConnection(bitmap: number): boolean {
  // #define REQPWD_CONNECTOR_PASSIVE 0x00000080 // bit on => passive connection for connectors
  // Passive mode is a way to override the default RDP profile (using OSC dialog) so RDP streamer can connect to another RDP server on-demand, if passive mode is enabled, will allow user to input IP/FQDN in the login form.
  // ref: https://splashtop.atlassian.net/wiki/spaces/RC/pages/290521854/Initial+production+changes#Passive-RDP-Connection
  const REQPWD_CONNECTOR_PASSIVE = 0x00000080;
  return Boolean(bitmap & REQPWD_CONNECTOR_PASSIVE);
}

const FT_WEB_CLIENT_SUPPORT_SAFARI = LOCAL_ONLY || DEVELOPMENT_ONLY;
export function checkDeviceBrowser() {
  const uaParser = new UAParser();
  const os = uaParser.getOS();

  // Currently don't support iOS
  if (os.name === 'iOS') {
    // Only enable ios support for development usage
    return FT_WEB_CLIENT_SUPPORT_SAFARI ? true : false;
  }

  const browser = uaParser.getBrowser();
  const supportBrowserList = ['Chrome', 'Firefox', 'Edge', 'Brave', 'Opera'];

  // Enable safari support for development usage or new version of safari
  if (FT_WEB_CLIENT_SUPPORT_SAFARI || !checkIsUnsupportedSafari()) {
    supportBrowserList.push('Safari');
  }
  return supportBrowserList.includes(browser.name || '');
}

export default function checkIsUnsupportedSafari() {
  try {
    const uaParser = new UAParser();
    const browser = uaParser.getBrowser();
    const isSafari = browser.name === 'Safari';
    const isSupported = compare(browser.version || '0', '15.6.1', '>=');
    return isSafari && !isSupported;
  } catch (error) {
    Sentry.captureException(error);
    return false;
  }
}

// Get bitmap number from api and use bitwise to calculate remote control status, definition is from fulong api spec
// backend_src_feature_capability is from 2.17 SRC CAPABILITY BIT-MAP, bit 67th
// session_asp is from 2.35 AGGREGATE SESSION PERMISSION BITMAP, bit 8th
export function checkRemoteControlsIsDisabled(backend_src_feature_capability: undefined | string, session_asp: undefined | string) {
  if (!backend_src_feature_capability || !session_asp) return false;

  const isSupportRemoteControl = getBitmapBit(backend_src_feature_capability, 67) === '1';
  const isEnabledRemoteControl = getBitmapBit(session_asp, 8) === '1';
  return isSupportRemoteControl && !isEnabledRemoteControl;
}

function hexToBinary(hex: string) {
  return hex
    .split('')
    .map((h) => parseInt(h, 16).toString(2).padStart(4, '0'))
    .join('');
}

export function getBitmapBit(hex: string, bitIndex: number) {
  const binaryString = hexToBinary(hex);
  return binaryString.charAt(binaryString.length - bitIndex);
}

export function getStreamerType(streamerVersion = ''): StreamerType {
  const { type: streamerOS, isRDP, isVNC, isRDS } = getOSType(streamerVersion);

  if (isRDP) {
    return 'rdp';
  }

  if (isRDS) {
    return 'rds';
  }

  if (isVNC) {
    return 'vnc';
  }

  return streamerOS;
}
