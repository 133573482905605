type SuccessArray = Array<number>;
type OfflineArray = Array<number>;

/**
 * Due to current api design, the offline means the action pass to streamer success, but streamer is offline, and the id is in both success and offline arrays.
 * For summary display, we need to filter the offline ids from success array.
 */
export const filterSuccessButOffline = (successArray: SuccessArray, offlineArray: OfflineArray) => {
  return successArray.filter((id) => !offlineArray.includes(id));
};
