import { useCallback, useMemo } from 'react';

import { groupBy } from 'lodash';
import keyBy from 'lodash/keyBy';
import { useTranslation } from 'react-i18next';

import { sortGroup } from '@/modules/Computer';
import { Group } from '@/services/group';
import { PLATFORMS, Policy } from '@/services/teams/emm_policies';
import { useTime } from '@/utils/formatDateTime';

import { createPolicyInheritanceTrees, createPreparedDefaultPolicy, getPreOrderDfs } from '../../utils';
import { ComputerDataForPolicy, useComputerListQuery } from '../useComputerListQuery';
import { useGroupListQuery } from '../useGroupListQuery';
import { GroupPolicyList, useGroupPolicyListQuery } from '../useGroupPolicyListQuery';
import { usePoliciesQuery } from '../usePoliciesQuery';
import { ServerPolicyList, useServerPolicyListQuery } from '../useServerPolicyListQuery';
import { PolicyRelationModel } from './types';
import { getGroupPolicyRelation, getPolicyAssignmentList, getServerPolicyRelation, getTeamPolicy } from './utils';

export * from './types';

type UsePolicyRelationModalProps =
  | {
      /**
       * Whether the queries are enabled or not
       * @default true
       */
      enabled: boolean;
    }
  | undefined;

/**
 * Construct the relation model between policies, groups and servers
 */
export const usePolicyRelationModel = ({ enabled }: UsePolicyRelationModalProps = { enabled: true }): PolicyRelationModel => {
  const { i18n } = useTranslation();
  const { formatDateTime } = useTime(i18n.language);
  const withDefaultGroupSelector = useCallback((groups: Array<Group>) => {
    return (
      [{ id: 0, name: 'Default Group' }, ...groups.map((group) => ({ id: group.id, name: group.name }))] as Array<
        Pick<Group, 'id' | 'name'>
      >
    ).toSorted(sortGroup);
  }, []);
  const groupsQuery = useGroupListQuery({
    select: withDefaultGroupSelector,
    enabled,
  });

  const supportEmmPolicySelector = useCallback(
    (computers: Array<ComputerDataForPolicy>) => computers.filter((computer) => computer.support_emm_policy),
    [],
  );
  const serversQuery = useComputerListQuery({
    select: supportEmmPolicySelector,
    enabled,
  });

  const withDefaultPolicySelector = useCallback((policies: Array<Policy>) => {
    const hasWinTeamDefault = policies.some((policy) => policy.platform === PLATFORMS.Windows && policy.super_root);
    const hasMacTeamDefault = policies.some((policy) => policy.platform === PLATFORMS.macOS && policy.super_root);
    const hasAndroidTeamDefault = policies.some((policy) => policy.platform === PLATFORMS.Android && policy.super_root);
    return [
      ...(hasWinTeamDefault ? [] : [createPreparedDefaultPolicy(PLATFORMS.Windows)]),
      ...(hasMacTeamDefault ? [] : [createPreparedDefaultPolicy(PLATFORMS.macOS)]),
      ...(hasAndroidTeamDefault ? [] : [createPreparedDefaultPolicy(PLATFORMS.Android)]),
      ...policies,
    ];
  }, []);
  const policiesQuery = usePoliciesQuery({
    select: withDefaultPolicySelector,
    enabled,
  });

  const policyMapSelector = useCallback((policies: Array<Policy>) => {
    return keyBy(policies, 'id');
  }, []);
  const policyMapQuery = usePoliciesQuery({
    select: policyMapSelector,
    enabled,
  });

  const dfsPolicies = useMemo(() => getPreOrderDfs(createPolicyInheritanceTrees(policiesQuery.data)), [policiesQuery.data]);

  const groupPolicyMapSelector = useCallback(
    (data: GroupPolicyList) => {
      return data.reduce(
        (acc, { group_id, emm_policy_id }) => {
          const policyPlatform = policyMapQuery.data?.[emm_policy_id]?.platform;
          if (!policyPlatform) {
            return acc;
          }
          acc[group_id] = { ...acc[group_id], [policyPlatform]: emm_policy_id };
          return acc;
        },
        {} as Record<number, { [PLATFORMS.macOS]: number; [PLATFORMS.Windows]: number; [PLATFORMS.Android]: number }>,
      );
    },
    [policyMapQuery.data],
  );
  const groupPolicyMapQuery = useGroupPolicyListQuery({
    select: groupPolicyMapSelector,
    enabled,
  });

  const serverPolicyMapSelector = useCallback((data: ServerPolicyList) => {
    return keyBy(data, 'server_id');
  }, []);
  const serverPolicyMapQuery = useServerPolicyListQuery({
    select: serverPolicyMapSelector,
    enabled,
  });

  const teamPolicy = useMemo(() => getTeamPolicy(policiesQuery.data), [policiesQuery.data]);

  const groupPolicyRelation = useMemo(() => {
    if (groupsQuery.isFetched && groupPolicyMapQuery.isFetched && policyMapQuery.isFetched) {
      return getGroupPolicyRelation({
        teamPolicy,
        groups: groupsQuery.data,
        groupPolicyMap: groupPolicyMapQuery.data,
        policyMap: policyMapQuery.data,
      });
    }
  }, [
    groupPolicyMapQuery.data,
    groupPolicyMapQuery.isFetched,
    groupsQuery.data,
    groupsQuery.isFetched,
    policyMapQuery.data,
    policyMapQuery.isFetched,
    teamPolicy,
  ]);

  const serverPolicyRelation = useMemo(() => {
    if (groupPolicyRelation && serversQuery.isFetched && serverPolicyMapQuery.isFetched && policyMapQuery.isFetched) {
      return getServerPolicyRelation({
        teamPolicy,
        groupPolicyRelation,
        servers: serversQuery.data,
        serverPolicyMap: serverPolicyMapQuery.data,
        policyMap: policyMapQuery.data,
      });
    }
  }, [
    groupPolicyRelation,
    policyMapQuery.data,
    policyMapQuery.isFetched,
    serverPolicyMapQuery.data,
    serverPolicyMapQuery.isFetched,
    serversQuery.data,
    serversQuery.isFetched,
    teamPolicy,
  ]);

  const policyServerMap = useMemo(() => groupBy(serverPolicyRelation, (node) => node.policy.id), [serverPolicyRelation]);

  const policyAssignmentList = useMemo(
    () =>
      getPolicyAssignmentList({
        groups: groupsQuery.data,
        servers: serversQuery.data,
        policies: dfsPolicies,
        policyServerMap,
        groupPolicyRelation,
        teamPolicy,
        formatDateTime,
      }),
    [groupsQuery.data, serversQuery.data, dfsPolicies, policyServerMap, groupPolicyRelation, teamPolicy, formatDateTime],
  );

  const isLoading = useMemo(
    () =>
      groupsQuery.isLoading ||
      serversQuery.isLoading ||
      policiesQuery.isLoading ||
      groupPolicyMapQuery.isLoading ||
      serverPolicyMapQuery.isLoading,
    [groupPolicyMapQuery.isLoading, groupsQuery.isLoading, policiesQuery.isLoading, serverPolicyMapQuery.isLoading, serversQuery.isLoading],
  );

  const isFetching = useMemo(
    () =>
      groupsQuery.isFetching ||
      serversQuery.isFetching ||
      policiesQuery.isFetching ||
      groupPolicyMapQuery.isFetching ||
      serverPolicyMapQuery.isFetching,
    [
      groupPolicyMapQuery.isFetching,
      groupsQuery.isFetching,
      policiesQuery.isFetching,
      serverPolicyMapQuery.isFetching,
      serversQuery.isFetching,
    ],
  );

  const isRefetching = useMemo(
    () =>
      groupsQuery.isRefetching ||
      serversQuery.isRefetching ||
      policiesQuery.isRefetching ||
      groupPolicyMapQuery.isRefetching ||
      serverPolicyMapQuery.isRefetching,
    [
      groupPolicyMapQuery.isRefetching,
      groupsQuery.isRefetching,
      policiesQuery.isRefetching,
      serverPolicyMapQuery.isRefetching,
      serversQuery.isRefetching,
    ],
  );

  const refetchPolicies = useCallback(() => {
    /** Due to the dependency, refetching policies will also refetch the group-policy map, and server-policy map */
    policiesQuery.refetch();
  }, [policiesQuery]);

  const refetch = useCallback(() => {
    groupsQuery.refetch();
    serversQuery.refetch();
    /** Due to the dependency, refetching policies will also refetch the group-policy map, and server-policy map */
    policiesQuery.refetch();
  }, [groupsQuery, policiesQuery, serversQuery]);

  return {
    refetch,
    refetchPolicies,
    isLoading,
    isFetching,
    isRefetching,
    teamPolicy,
    sortedGroupIds: groupsQuery.data?.map((group) => group.id) || [],
    groups: groupsQuery.data,
    servers: serversQuery.data,
    policies: policiesQuery.data,
    policyAssignmentList,
    groupPolicyRelation,
    serverPolicyRelation,
    policyServerMap,
  };
};
