import { useCallback } from 'react';

import { useAtomValue, useSetAtom } from 'jotai';

import { type Reason } from '@/services/users/sendVerificationEmail';
import { showErrorFlashMessage } from '@/utils/errorHandling';

import { useEmailVerificationModal } from './EmailVerificationModal';
import { checkEmailVerificationAtom, userProfileAtom } from './atoms';

export function useUserProfile() {
  return useAtomValue(userProfileAtom);
}

export function useEmailVerification() {
  const { email, email_verified } = useAtomValue(userProfileAtom);
  const checkEmailVerificationAction = useSetAtom(checkEmailVerificationAtom);

  const emailVerificationModal = useEmailVerificationModal();

  const isEmailVerified = email_verified;

  const checkEmailVerification = useCallback(
    async ({ reason }: { reason: Reason }): Promise<{ verified: boolean }> => {
      return checkEmailVerificationAction({ reason })
        .then(({ verified }) => {
          if (!verified) {
            emailVerificationModal.open({ email });
          }

          return Promise.resolve({ verified });
        })
        .catch((error) => {
          showErrorFlashMessage({ error });

          return Promise.reject('Unexpected error');
        });
    },
    [checkEmailVerificationAction, email, emailVerificationModal],
  );

  return {
    isEmailVerified,
    /**
     * Check email verification status.
     * If email is not verified, show email verification modal.
     *
     * @example
     * ```ts
     * checkEmailVerification({ reason: 'premium_tool' })
     *   .then(({ verified }) => {
     *     if (verified) {
     *       // Email is already verified
     *     } else {
     *       // Email is not verified, automatically show email verification modal
     *     }
     *   })
     *   .catch(() => {
     *     // Unexpected error
     *   })
     * ```
     */
    checkEmailVerification,
  };
}
