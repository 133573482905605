import { Flex, forwardRef, useMultiStyleConfig } from '@chakra-ui/react';
import { useAtom, useAtomValue } from 'jotai';
import { useTranslation } from 'next-i18next';

import {
  RippleArrowDown16,
  RippleArrowUp16,
  RippleButton,
  RippleHeading08,
  RipplePopover,
  RipplePopoverContent,
  RipplePopoverTrigger,
  RippleSelectButton,
  RippleStrong,
} from '@/design';
import { RippleButtonProps } from '@/design';
import { featureControl } from '@/feature/toggle';

import { ComputerGroupMenu } from '../../ComputerGroupMenu';
import { filterGroupAtom, groupNameMapAtom, manageableGroupListAtom } from '../../atoms';
import { FilterGroup as FilterGroupValue } from '../../types';
import { useShowDefaultGroup } from '../../utils';

export function FilterGroup(): React.JSX.Element {
  const { t } = useTranslation();
  const [filterGroup, setFilterGroup] = useAtom(filterGroupAtom);
  const groupNameMap = useAtomValue(groupNameMapAtom);
  const groupList = useAtomValue(manageableGroupListAtom);
  const showDefaultGroup = useShowDefaultGroup();

  const selectedGroup = { id: filterGroup, name: groupNameMap[filterGroup] };

  return (
    <Flex flexDirection="column">
      <RippleHeading08 mb="8px">{t('computer:filterDrawer.group.label')}</RippleHeading08>
      <RipplePopover placement="bottom-start" matchWidth>
        {({ isOpen, onClose }) => (
          <>
            <RipplePopoverTrigger>
              {featureControl.getToggle('PCP_3954__Redesign_Ripple_Select_and_Menu') ? (
                <RippleSelectButton isOpen={isOpen}>{selectedGroup.name}</RippleSelectButton>
              ) : (
                <SelectButton isOpen={isOpen}>{selectedGroup.name}</SelectButton>
              )}
            </RipplePopoverTrigger>
            <RipplePopoverContent w="100%" boxShadow="8px">
              <ComputerGroupMenu
                showAllGroup
                showDefaultGroup={showDefaultGroup}
                showFromOtherGroup={false}
                groups={groupList}
                onSelect={(selectedGroup) => {
                  setFilterGroup(selectedGroup.id as FilterGroupValue);
                  onClose();
                }}
                selectMode
                selectedGroup={selectedGroup}
              />
            </RipplePopoverContent>
          </>
        )}
      </RipplePopover>
    </Flex>
  );
}
type SelectButtonProps = RippleButtonProps & { isOpen: boolean };
/**
 * @deprecated Copied & modified from RippleSelectButtonWithContext, should use latest `RippleSelectButton`
 */
const SelectButton = forwardRef<SelectButtonProps, 'button'>(({ isOpen, children, ...otherProps }, ref): React.JSX.Element => {
  const styles = useMultiStyleConfig('rippleSelect');

  return (
    <RippleButton data-testid="filter-group-select-button" ref={ref} isActive={isOpen} __css={styles.button} {...otherProps}>
      <Flex justifyContent="space-between" alignItems="center">
        <RippleStrong as="div" variant="strong02" color="inherit" isTruncated>
          {children}
        </RippleStrong>
        {isOpen ? <RippleArrowUp16 color="inherit" /> : <RippleArrowDown16 color="inherit" />}
      </Flex>
    </RippleButton>
  );
});
