import { useCallback } from 'react';

import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import keyBy from 'lodash/keyBy';

import { featureControl } from '@/feature/toggle';
import { useTeamExpiration } from '@/models/TeamInformation';
import {
  GroupPolicyList,
  ServerPolicyList,
  useGroupPolicyListQuery,
  usePoliciesQuery,
  useServerPolicyListQuery,
} from '@/modules/PolicyList/hooks';
import { ComputerData } from '@/services/computers';
import { PLATFORMS, Platform, Policy } from '@/services/teams/emm_policies';

import { selectedGroupMapAtom, selectedServerMapAtom } from '../../atoms';
import {
  filterCountAtom,
  followGroupFilterAtom,
  groupIdFilterAtom,
  isSelectedOnlyFilterAtom,
  policyFilterSetAtom,
  resetAllFilterAtom,
  setPolicyFilterAtom,
  toggleSelectedOnlyAtom,
} from './atoms';

export const useTableFilter = () => {
  const { isTeamExpired } = useTeamExpiration();

  const policyMapSelector = useCallback((policies: Array<Policy>) => keyBy(policies, 'id'), []);
  const policyMap = usePoliciesQuery({
    select: policyMapSelector,
    enabled: featureControl.getToggle('PCP_4181__Policy_list__performance_improvement') ? false : !isTeamExpired,
  });

  const serverPolicyMapSelector = useCallback((data: ServerPolicyList) => keyBy(data, 'server_id') ?? {}, []);
  const serverPolicyMapQuery = useServerPolicyListQuery({
    select: serverPolicyMapSelector,
    enabled: featureControl.getToggle('PCP_4181__Policy_list__performance_improvement') ? false : !isTeamExpired,
  });

  const groupPolicyMapSelector = useCallback(
    (data: GroupPolicyList) =>
      data.reduce(
        (acc, { group_id, emm_policy_id }) => {
          const policyPlatform = policyMap.data?.[emm_policy_id]?.platform;
          if (!policyPlatform) {
            return acc;
          }
          acc[group_id] = { ...acc[group_id], [policyPlatform]: emm_policy_id };
          return acc;
        },
        {} as Record<number, { [PLATFORMS.macOS]: number; [PLATFORMS.Windows]: number; [PLATFORMS.Android]: number }>,
      ),
    [policyMap.data],
  );
  const groupPolicyMapQuery = useGroupPolicyListQuery({
    select: groupPolicyMapSelector,
    enabled: featureControl.getToggle('PCP_4181__Policy_list__performance_improvement') ? false : !isTeamExpired,
  });

  const isSelectedOnlyFilter = useAtomValue(isSelectedOnlyFilterAtom);
  const [groupIdFilter, setGroupIdFilter] = useAtom(groupIdFilterAtom);
  const policyFilterSet = useAtomValue(policyFilterSetAtom);
  const followGroupFilter = useAtomValue(followGroupFilterAtom);
  const selectedServerMap = useAtomValue(selectedServerMapAtom);
  const selectedGroupMap = useAtomValue(selectedGroupMapAtom);
  const filterCount = useAtomValue(filterCountAtom);
  const resetAllFilter = useSetAtom(resetAllFilterAtom);

  const isFilteredBySelectedGroup = useCallback(
    (group: { id: number; name: string }, isFallbackSelected: boolean): boolean =>
      !isSelectedOnlyFilter || selectedGroupMap[group.id] || isFallbackSelected,
    [isSelectedOnlyFilter, selectedGroupMap],
  );
  const isFilteredBySelectedServer = useCallback(
    (server: ComputerData): boolean => !isSelectedOnlyFilter || selectedServerMap[server.id],
    [isSelectedOnlyFilter, selectedServerMap],
  );
  const isFilteredByGroup = useCallback(
    (group: { id: number; name: string }): boolean => typeof groupIdFilter === 'undefined' || group.id === groupIdFilter,
    [groupIdFilter],
  );
  const isServerFilteredByPolicy = useCallback(
    (server: ComputerData): boolean => {
      const hasPolicyFilter = policyFilterSet.size > 0;
      if (!hasPolicyFilter && !followGroupFilter) {
        return true;
      }
      const serverPolicyId = serverPolicyMapQuery.data?.[server.id]?.emm_policy_id;
      if (serverPolicyId && hasPolicyFilter) {
        return policyFilterSet.has(serverPolicyId);
      }
      return followGroupFilter && !serverPolicyId;
    },
    [followGroupFilter, policyFilterSet, serverPolicyMapQuery.data],
  );
  const isGroupFilteredByPolicy = useCallback(
    ({ groupId, platform, teamPolicyId }: { groupId: number; platform: Platform; teamPolicyId: number }) => {
      const hasPolicyFilter = policyFilterSet.size > 0;
      if (!hasPolicyFilter) {
        return true;
      }
      const groupPolicyId = groupPolicyMapQuery.data?.[groupId]?.[platform];
      return policyFilterSet.has(groupPolicyId || teamPolicyId);
    },
    [groupPolicyMapQuery.data, policyFilterSet],
  );

  return {
    groupFilter: groupIdFilter,
    isServerFilteredByPolicy,
    isGroupFilteredByPolicy,
    isFilteredByGroup,
    isFilteredBySelectedGroup,
    isFilteredBySelectedServer,
    policyFilterSet,
    followGroupFilter,
    isSelectedOnlyFilter,
    filterCount,
    setGroupFilter: setGroupIdFilter,
    resetAllFilter,
    setPolicyFilter: useSetAtom(setPolicyFilterAtom),
    setFollowGroupFilter: useSetAtom(followGroupFilterAtom),
    toggleIsSelectedOnly: useSetAtom(toggleSelectedOnlyAtom),
  };
};
