import { useCallback } from 'react';

import { useQuery } from '@tanstack/react-query';
import { keyBy, matches } from 'lodash';

import { useTeamId } from '@/models/TeamInformation';
import { osPatchPolicyItemConfig } from '@/services/teams/emm_policies/os_patch/constants';
import { OSPatchPolicyDetail, getOSPatchPolicyDetailService } from '@/services/teams/emm_policies/os_patch/getOSPatchPolicyDetail';

/** Check if the os patch enabled */
export const useOsPatchEnabledCheckQuery = ({ policyId, enabled }: { policyId?: number; enabled?: boolean }) => {
  const teamId = useTeamId();
  const selector = useCallback((data: OSPatchPolicyDetail | false) => {
    if (!data) {
      return false;
    }
    const currentConfig = data.current.policy_items.find(matches({ code: osPatchPolicyItemConfig.enable_policy.code }));
    if (currentConfig) {
      return Boolean(currentConfig.value);
    }
    if (!data.parents) {
      return false;
    }
    const policyMap = keyBy(data.parents, 'emm_policy_id');
    const checkParentEnabled = (parentPolicyId: number | null): boolean => {
      if (parentPolicyId === null) {
        return false;
      }
      const config = policyMap[parentPolicyId];
      if (!config) {
        return false;
      }
      const inheritedEnable = config.policy_items.find(matches({ code: osPatchPolicyItemConfig.enable_policy.code }));
      return inheritedEnable ? Boolean(inheritedEnable.value) : checkParentEnabled(config.parent_id);
    };
    return checkParentEnabled(data.current.parent_id);
  }, []);

  return useQuery({
    queryKey: ['osPatchDetails', teamId, policyId],
    enabled,
    queryFn: async () => {
      if (!policyId) {
        return false;
      }
      return await getOSPatchPolicyDetailService.execute(teamId, policyId);
    },
    select: selector,
  });
};
