import { useQuery } from '@tanstack/react-query';
import chunk from 'lodash/chunk';

import { useTeamId, useTeamInformation } from '@/models/TeamInformation';
import {
  ComputerData,
  ComputerListCustomizedField,
  computerListByTokenAvailableTeamsSchema,
  getComputerIds,
  getComputerListById,
  getComputerListByToken,
  getTeamComputerTokenList,
} from '@/services/computers';
import { CHUNK_SIZE } from '@/utils/constants';

export type ComputerDataForPolicy = Pick<
  ComputerData,
  | 'id'
  | 'name'
  | 'version'
  | 'group_id'
  | 'group_name'
  | 'online_status'
  | 'support_emm_policy'
  | 'support_emm_os_policy'
  | 'support_emm_patch_policy'
  | 'support_emm_streamer_policy'
>;

type UseComputerListQueryProps<T> = {
  select?: (data: Array<ComputerDataForPolicy>) => T;
  enabled?: boolean;
};

export const useComputerListQuery = <TData = Array<ComputerDataForPolicy>>({
  select,
  enabled = true,
}: UseComputerListQueryProps<TData> = {}) => {
  const { teamKind } = useTeamInformation();
  const teamId = useTeamId();
  return useQuery({
    queryKey: ['policy_assign_modal', 'computer_list', teamId, teamKind],
    queryFn: async () => {
      const customizedFields: Array<ComputerListCustomizedField> = [
        'group_id',
        'group_name',
        'online_status',
        'support_emm_policy',
        'version',
      ];
      // Retrieve computer list by token for SRS team
      if (computerListByTokenAvailableTeamsSchema.safeParse(teamKind).success) {
        const tokenList = await getTeamComputerTokenList(teamId);
        const results = await Promise.all(
          tokenList.map((token) => getComputerListByToken(teamId, { token, customizedFields, mode: 'customize' })),
        );
        return results.flat();
      }
      // Retrieve computer list by id for other teams
      const ids = await getComputerIds(teamId);
      if (ids.length === 0) {
        return [];
      }
      const idListChunks = chunk(ids, CHUNK_SIZE);
      const result = await Promise.all(idListChunks.map((idList) => getComputerListById(teamId, idList, 'customize', customizedFields)));
      return result.flat();
    },
    staleTime: 30 * 60 * 1_000, // 30 minutes
    gcTime: 35 * 60 * 1_000, // 35 minutes
    select,
    enabled,
  });
};
