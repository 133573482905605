import { DateRangeFilter } from './types';

export const opportunityStatusMap = {
  PENDING: 0,
  APPROVED: 1,
  REJECTED: 2,
  FAILED: 3,
  INSTALLED: 4,
} as const;

export const opportunityStatusLabelsMap = {
  [opportunityStatusMap.PENDING]: 'pending',
  [opportunityStatusMap.APPROVED]: 'approved',
  [opportunityStatusMap.REJECTED]: 'rejected',
  [opportunityStatusMap.FAILED]: 'failed',
  [opportunityStatusMap.INSTALLED]: 'installed',
} as const;

export const opportunityStatusList = Object.values(opportunityStatusLabelsMap);

export const ALL_GROUP_ID = 'all';
export const DEFAULT_GROUP_ID = '0';
export const DEFAULT_DATE_RANGE = 90;

const last_count_days = 'common:last_count_days';

export const DATE_RANGES: Map<DateRangeFilter, { label: string; count: number }> = new Map([
  [1, { label: 'common:last_count_hours', count: 24 }],
  [3, { label: last_count_days, count: 3 }],
  [7, { label: last_count_days, count: 7 }],
  [30, { label: last_count_days, count: 30 }],
  [60, { label: last_count_days, count: 60 }],
  [90, { label: last_count_days, count: 90 }],
]);

export const PLATFORMS = ['win', 'mac'] as const;
