import { useEffect, useState } from 'react';

import { Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import {
  RippleButton,
  RippleModal,
  RippleModalBody,
  RippleModalContent,
  RippleModalFooter,
  RippleModalHeader,
  RippleModalProps,
  RippleModalTitle,
  RippleSingleSelect,
  RippleSingleSelectOption,
  RippleTooltip,
  RippleTypography,
} from '@/design';
import { featureControl } from '@/feature/toggle';
import { useTeamExpiration } from '@/models/TeamInformation';
import { createPreparedDefaultPolicy } from '@/modules/PolicyList/utils';
import { Platform, Policy } from '@/services/teams/emm_policies';

export const STRATEGY = {
  group: 'group',
  server: 'server',
} as const;

export type Strategy = keyof typeof STRATEGY;

type ComputerPolicyAssignmentProps = {
  platform: Platform;
  policies: Array<Policy>;
  serverPolicyId?: number;
  groupPolicyId?: number;
  teamPolicyId?: number;
  size?: RippleModalProps['size'];
  onSave: (isFollowGroup: boolean, policyId?: number) => void;
  onClose: () => void;
  isSaving: boolean;
};

export const ComputerPolicyAssignment = ({
  size,
  policies,
  platform,
  teamPolicyId,
  groupPolicyId,
  serverPolicyId,
  onSave,
  onClose,
  isSaving,
}: ComputerPolicyAssignmentProps) => {
  const teamPolicy = policies.find((policy) => policy.super_root);
  const { isTeamExpired } = useTeamExpiration();
  const [strategy, setStrategy] = useState<Strategy>('group');
  const [selectedPolicyId, setSelectedPolicyId] = useState<number | undefined>(undefined);
  const isFollowGroup = strategy === 'group';
  const { t } = useTranslation();

  const handleStrategyChange = (strategy?: Strategy) => {
    if (strategy === 'group') {
      setStrategy(strategy);
      setSelectedPolicyId(groupPolicyId ?? teamPolicyId);
      return;
    }
    if (strategy === 'server') {
      setStrategy(strategy);
    }
  };
  const handlePolicyChange = (id?: string) => {
    setSelectedPolicyId(id ? Number(id) : undefined);
  };
  const handleSave = () => {
    onSave(isFollowGroup, selectedPolicyId);
  };

  useEffect(
    function initPolicy() {
      if (serverPolicyId) {
        setSelectedPolicyId(serverPolicyId);
        setStrategy('server');
        return;
      }
      if (groupPolicyId) {
        setSelectedPolicyId(groupPolicyId);
        setStrategy('group');
        return;
      }
      if (teamPolicyId) {
        setSelectedPolicyId(teamPolicyId);
        setStrategy('group');
        return;
      }
      onClose();
    },
    [groupPolicyId, onClose, serverPolicyId, teamPolicy, teamPolicyId],
  );

  const preparedPolicy = createPreparedDefaultPolicy(platform);
  const isSamePolicy = isFollowGroup ? typeof serverPolicyId === 'undefined' : serverPolicyId === selectedPolicyId;

  return (
    <RippleModal isOpen={true} onClose={onClose} size={size}>
      <RippleModalContent>
        <RippleModalHeader>
          <RippleModalTitle>{t('assign-policy:edit_policy')}</RippleModalTitle>
        </RippleModalHeader>
        <RippleModalBody>
          <Stack gap="32px">
            <RippleTypography variant="body02">
              {t('assign-policy:all_overrides_will_be_cleared_when_changing_the_policy')}
            </RippleTypography>
            <Stack gap="8px">
              <RippleTypography variant="heading09">{t('assign-policy:policy')}</RippleTypography>
              <RippleSingleSelect value={strategy} placeholder="" title={t('assign-policy:policy')} onChange={handleStrategyChange}>
                <RippleSingleSelectOption value="server">
                  <RippleTooltip label={t('assign-policy:specify_a_policy')}>
                    <RippleTypography variant="body02" textAlign="left" isTruncated>
                      {t('assign-policy:specify_a_policy')}
                    </RippleTypography>
                  </RippleTooltip>
                </RippleSingleSelectOption>
                <RippleSingleSelectOption value="group">
                  <RippleTooltip label={t('assign-policy:follow_group')}>
                    <RippleTypography variant="body02" textAlign="left" isTruncated>
                      {t('assign-policy:follow_group')}
                    </RippleTypography>
                  </RippleTooltip>
                </RippleSingleSelectOption>
              </RippleSingleSelect>
            </Stack>
            <Stack gap="8px">
              <RippleTypography variant="heading09">{t('assign-policy:select_a_policy')}</RippleTypography>
              {policies.length ? (
                <RippleSingleSelect
                  value={selectedPolicyId ? String(selectedPolicyId) : undefined}
                  placeholder=""
                  onChange={handlePolicyChange}
                  menuProps={{ placement: 'top' }}
                  menuListSx={
                    featureControl.getToggle('PCP_3954__Redesign_Ripple_Select_and_Menu')
                      ? { maxHeight: '224px', minW: '128px', maxW: '592px' }
                      : { maxHeight: '224px' }
                  }
                  isDisabled={strategy === 'group'}
                >
                  {policies.map((policy) => {
                    return (
                      <RippleSingleSelectOption key={policy.id} value={String(policy.id)}>
                        <RippleTooltip label={policy.name}>
                          <RippleTypography variant="body02" textAlign="left" isTruncated>
                            {policy.name}
                          </RippleTypography>
                        </RippleTooltip>
                      </RippleSingleSelectOption>
                    );
                  })}
                </RippleSingleSelect>
              ) : (
                <RippleSingleSelect value={String(preparedPolicy.id)} placeholder="" isDisabled={strategy === 'group'} onChange={() => {}}>
                  <RippleTooltip label={preparedPolicy.name}>
                    <RippleSingleSelectOption value={String(preparedPolicy.id)}>{preparedPolicy.name}</RippleSingleSelectOption>
                  </RippleTooltip>
                </RippleSingleSelect>
              )}
            </Stack>
          </Stack>
        </RippleModalBody>
        <RippleModalFooter gap="8px">
          <RippleButton variant="grayScaleGhost" onClick={onClose}>
            {t('common:cancel')}
          </RippleButton>
          <RippleButton onClick={handleSave} isDisabled={isTeamExpired || isSamePolicy} isLoading={isSaving}>
            {t('common:save')}
          </RippleButton>
        </RippleModalFooter>
      </RippleModalContent>
    </RippleModal>
  );
};
