import { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';

import { featureControl } from '@/feature/toggle';
import { getUserBasicProfile } from '@/services/common';
import { infoQueryService } from '@/services/users';

import { basicProfileAtom } from './atoms';
import { infoQuery_queryMap } from './constants';
import { BasicProfile } from './types';

export function useRefreshBasicProfile() {
  const setBasicProfile = useSetAtom(basicProfileAtom);

  const basicProfileQuery = useQuery<BasicProfile>({
    queryKey: ['basicProfile'],
    queryFn: async () => {
      const basicProfile = await getUserBasicProfile();
      const infoQuery = featureControl.getToggle('PCP_4092__Add_info_query_data_into_basicProfileAtom')
        ? await infoQueryService.execute(infoQuery_queryMap)
        : {};

      return {
        ...basicProfile,
        infoQuery,
      };
    },
    enabled: false,
  });

  useEffect(() => {
    if (basicProfileQuery.data) {
      setBasicProfile(basicProfileQuery.data);
    }
  }, [basicProfileQuery.data, setBasicProfile]);

  return basicProfileQuery.refetch;
}
